.App {
  height: 100%;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

/*  BUTTON HOVER BAMNER */

.button-contacto {
  transition: all 1s;
}

.button-contacto:hover {
  color: #2B9B1F;
  background-color: #FFFFFF;
  transform: translateY(-5px);
}

.button-servicio {
  transition: all 1s;
}


.button-servicio:hover {
  color: #FFFFFF !important;
  background-color: #2B9B1F !important;
  transform: translateY(-5px);
}

/*  BUTTON HOVER SERVICES */
.button-services {
  transition: all 1s;
}

.button-services:hover {
  color: #FFF;
  background-color: #181C28;
  margin-top: -20px;
}





.div-section-information {
  top: 0;
  animation-name: movediv;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;

}

@media (max-width: 650px) {
  .div-section-information {
    top: 0;
    position: relative !important;
    animation: none !important;
  }
}

@keyframes moveUp {
  from {
    top: 0px;
  }

  to {
    top: -20px;
  }

  100% {
    top: -20px;
  }
}

@keyframes movediv {
  from {
    top: 0px;
    background: transparent;
  }

  to {
    top: -110px;
  }
}





.img-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: backgroundAnimation 10s linear infinite;
  animation-timing-function: ease-in;
  z-index: 0;
}


@keyframes backgroundAnimation {
  0% {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./assets/img/photo1.jpg');
  }

  50% {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./assets/img/photo2.jpg');
  }

  100% {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./assets/img/photo3.jpg');

  }

}